import {mapGetters, mapMutations} from "vuex";

export default {
  name: 'form-thanks-popup',
  computed: {
    ...mapGetters({
      popupThanks: 'system/showThanksPopup',
    })
  },
  methods: {
    ...mapMutations({
      changeShowThanksPopupShow: `system/CHANGE_FORM_THANKS_POPUP`,
    })
  },
  destroyed() {
  }
}
